import {
  GetTargetListRequesting,
  GetTargetListSuccess,
  GetTargetListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetTargetList(state = defalutState, action) {
  switch (action.type) {
    case GetTargetListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetTargetListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetTargetListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
