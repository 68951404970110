import {
  GetTaStudentListRequesting,
  GetTaStudentListSuccess,
  GetTaStudentListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetTaStudentList(state = defalutState, action) {
  switch (action.type) {
    case GetTaStudentListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetTaStudentListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetTaStudentListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
