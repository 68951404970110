import {
  PostQuestionTextRequesting,
  PostQuestionTextSuccess,
  PostQuestionTextError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function PostQuestionText(state = defalutState, action) {
  switch (action.type) {
    case PostQuestionTextRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case PostQuestionTextSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case PostQuestionTextError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
