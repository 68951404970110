import React from "react";
import { isLocal, getCookie } from "./../webConfig";

export default function isLogin(Component) {
  class IsLogin extends React.Component {
    componentDidMount() {
      this.isLogin();
    }
    isLogin() {
      if (!getCookie()) {
        // 没有登陆
        if (isLocal()) {
          // 是本地
          const redirectAfterLogin = this.props.location.pathname;
          this.props.history.push(`/login?next=${redirectAfterLogin}`);
        } else {
          window.location.href = `${window.location.protocol}//${window.location.host}/back/`;
          setInterval(() => {
            window.location.reload(true);
          }, 1000);
        }
      }
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  return IsLogin;
}
