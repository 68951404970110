import {
  GetAnswerSheetListRequesting,
  GetAnswerSheetListSuccess,
  GetAnswerSheetListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetAnswerSheetList(state = defalutState, action) {
  switch (action.type) {
    case GetAnswerSheetListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetAnswerSheetListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetAnswerSheetListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
