import {
  BindQuestionTagRequesting,
  BindQuestionTagSuccess,
  BindQuestionTagError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function BindQuestionTag(state = defalutState, action) {
  switch (action.type) {
    case BindQuestionTagRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case BindQuestionTagSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case BindQuestionTagError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
