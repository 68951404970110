import {
  GetBatchExamListRequesting,
  GetBatchExamListSuccess,
  GetBatchExamListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetBatchExamList(state = defalutState, action) {
  switch (action.type) {
    case GetBatchExamListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetBatchExamListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetBatchExamListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
