import {
  CreateTestRequesting,
  CreateTestSuccess,
  CreateTestError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function CreateTest(state = defalutState, action) {
  switch (action.type) {
    case CreateTestRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case CreateTestSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case CreateTestError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
