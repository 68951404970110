import {
  PostBatchExamRequesting,
  PostBatchExamSuccess,
  PostBatchExamError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function PostBatchExam(state = defalutState, action) {
  switch (action.type) {
    case PostBatchExamRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case PostBatchExamSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case PostBatchExamError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
