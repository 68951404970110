import {
  PostUserInfoRequesting,
  PostUserInfoSuccess,
  PostUserInfoError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function PostUserInfo(state = defalutState, action) {
  switch (action.type) {
    case PostUserInfoRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case PostUserInfoSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case PostUserInfoError:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}
