import {
  DELETEQuestionImgRequesting,
  DELETEQuestionImgSuccess,
  DELETEQuestionImgError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function DELETEQuestionImg(state = defalutState, action) {
  switch (action.type) {
    case DELETEQuestionImgRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case DELETEQuestionImgSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case DELETEQuestionImgError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
