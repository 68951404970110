let lang = {
  index: {
    home: "Home",
  },

  header: {
    uploader: {
      ManageTests: "Manage Tests",
      ManageQuestions: "Manage Questions",
      TestPaperTemplate: "Test Paper Template",
      BatchUpload: "Batch Upload",
    },
    teacher: {
      Students: "Students",
      ManageQuestions: "Manage Questions",
      Ranking: "Ranking",
    },
    student: {
      Mock: "Mock",
      Review: "Review",
      Pactice: "Pactice",
      Redo: "Redo",
      AITA: "AI——TA",
      Stats: "Stats",
      Resource: "Resource",
      ContaceUs: "Contace Us",
      Universal: "Universal",
      BindWeChat: "Bind WeCaht",
    },
    ta: {
      TA: "TA",
    },
  },
};

export { lang };
