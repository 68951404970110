let lang = {
  index: {
    home: "首页",
  },

  header: {
    uploader: {
      ManageTests: "测试管理",
      ManageQuestions: "问题管理",
      TestPaperTemplate: "试卷模板",
      BatchUpload: "批量上传",
    },
    teacher: {
      Students: "学生",
      ManageQuestions: "问题管理",
      Ranking: "排名",
    },
    ta: {
      TA: "TA",
    },
  },
};

export { lang };
