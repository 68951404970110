import {
  GetTeacherMockListRequesting,
  GetTeacherMockListSuccess,
  GetTeacherMockListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetTeacherMockList(state = defalutState, action) {
  switch (action.type) {
    case GetTeacherMockListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetTeacherMockListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetTeacherMockListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
