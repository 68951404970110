import {
  PostQuestionImgRequesting,
  PostQuestionImgSuccess,
  PostQuestionImgError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function PostQuestionImg(state = defalutState, action) {
  switch (action.type) {
    case PostQuestionImgRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case PostQuestionImgSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case PostQuestionImgError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
