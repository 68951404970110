import {
  GetTagTypesRequesting,
  GetTagTypesSuccess,
  GetTagTypesError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetTagTypes(state = defalutState, action) {
  switch (action.type) {
    case GetTagTypesRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetTagTypesSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetTagTypesError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
