import {
  GetMockListRequesting,
  GetMockListSuccess,
  GetMockListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetMockList(state = defalutState, action) {
  switch (action.type) {
    case GetMockListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetMockListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetMockListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
