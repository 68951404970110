import {
  GetWankingListRequesting,
  GetWankingListSuccess,
  GetWankingListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetWankingList(state = defalutState, action) {
  switch (action.type) {
    case GetWankingListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetWankingListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetWankingListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
