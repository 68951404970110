import { loginSuccess } from "./store/action-type";
import { message } from "antd";
import store from "./store/index";
let Documents = document;
// 是否为本地
export function isLocal() {
  let port = window.location.port;
  let hostHome = window.location.hostname;
  if (
    hostHome === "127.0.0.1" ||
    hostHome === "localhost" ||
    port === "3000" ||
    port === "3001"
  ) {
    return true;
  } else {
    return false;
  }
}
// 取cookie
export function getCookies(name) {
  let localCookies = Documents.cookie.split("; ");
  for (let i = 0; i < localCookies.length; i++) {
    let cookieV = localCookies[i].split("=");
    if (cookieV[0] === name) {
      if (cookieV[1].length <= 2) {
        return null;
      } else {
        return cookieV[1];
      }
    }
  }
}
// 存Cookie
export function setCookies(name, value) {
  Documents.cookie = name + "=" + value + "; path=" + "/";
}
// 取token
export function getCookie() {
  if (isLocal()) {
    return localStorage.getItem("spikeToken");
  } else {
    let getStoreToken = store.getState().login.token;
    let getCookieTo = getCookies("jwt_token");
    if (getStoreToken) {
      return getStoreToken;
    } else if (getCookieTo) {
      // 先存起来
      getStore({ type: loginSuccess, value: getCookieTo });
      return getCookieTo;
    } else {
      return null;
    }
  }
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem("userInfo"));
}

// 改变 store
export function getStore(data) {
  store.dispatch(data);
}

function getIp() {
  if (isLocal()) {
    return "http://localhost:8000/";
  } else {
    // return 'http://localhost:8000/'
    return window.location.protocol + "//" + window.location.host + "/";
  }
}

export const URL = getIp();

export function requestStatus(data, text) {
  if (!text) text = "";
  // message.destroy()
  if (data) {
    if (data.status) {
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // message.success(`${text}成功`)
      } else if (data.status === 401) {
        let textCode = JSON.stringify(data.data);
        message.error(`${text}失败,${textCode}`);
      } else if (data.status === 403) {
        let text = data.data[Object.keys(data.data)];
        message.error(text);
        if (text === "权限不足") {
          window.location.href = `${window.location.protocol}//${window.location.host}/`;
        }
        if (text === "Authentication credentials were not provided.") {
          localStorage.clear();
          document.cookie = "jwt_token='';Path=/";

          window.location.reload(true);
        }
      } else if (data.status === 404) {
        message.error(`${text}状态码：${data.data[Object.keys(data.data)]}`);
      } else if (data.status === 400) {
        message.error(`${text}状态码：${data.data[Object.keys(data.data)][0]}`);
      } else {
        message.error(`${text}状态码：${data.status}----发生未知错误`);
      }
    } else {
      message.error("发送请求失败！");
    }
  } else {
    message.error(`${text}状态码：${data}----发生未知错误`);
  }
  return data;
}

// 判断权限人员

export function isUser(data, user) {
  if (data && data.length !== 0 && user) {
    if (data.indexOf(user) !== -1) return true;
  } else {
    return false;
  }
}

// 处理utc时间

export function format(num) {
  var f = num < 10 ? "0" + num : num;
  return f;
}
export function times(utc_str) {
  if (!utc_str)
    return {
      time: null, /// 时间戳
      utcYear: null,
      utcMonth: null,
      utcDate: null,
      utcHours: null,
      utcMinUtes: null,
      utcHM: null,
      utcYMD: null,
      utcYMDHM: null,
    };
  let date_obj = new Date(utc_str);
  // let time = date_obj.getTime();
  let time = date_obj.getTime() + 8 * 60 * 60 * 1000;
  date_obj = new Date(time);

  let year = date_obj.getFullYear(); // 年
  let month = date_obj.getMonth() + 1; //月
  let day = date_obj.getDate(); //  日
  let hours = date_obj.getHours(); //  时
  let minutes = date_obj.getMinutes(); //  分
  let utcDataTime = {
    time: time, /// 时间戳
    utcYear: year,
    utcMonth: month,
    utcDate: day,
    utcHours: hours,
    utcMinUtes: minutes,
    utcHM: format(hours) + " : " + format(minutes),
    utcYMD: year + "-" + format(month) + "-" + format(day),
    utcYMDHM:
      year +
      "-" +
      format(month) +
      "-" +
      format(day) +
      " " +
      format(hours) +
      " : " +
      format(minutes),
  };
  return utcDataTime;
}

//

export function utc_to_english(utc_str) {
  const monthMapping = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  if (!utc_str) return null;
  let date_obj = new Date(utc_str);
  date_obj.setHours(date_obj.getHours() + 8);
  let year = date_obj.getFullYear();
  let month = date_obj.getMonth() + 1;
  const monthStr = monthMapping[month];
  let day = date_obj.getDate();

  let hours = date_obj.getHours();
  let minutes = date_obj.getMinutes();
  return `${hours}:${minutes} ${day}th ${monthStr},${year}`;
}

// input 输入防抖

export function inputDebounce(fn, time) {
  let timer = null;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, time);
  };
}

// 文件URL

export function returnFileUrl(url) {
  if (!url) return url;
  let newUrl = url.substring(0, 5);
  if (newUrl === "https") {
    return url;
  } else if (newUrl === "data:") {
    return url;
  } else {
    return URL + url;
  }
}
// 按照特殊字符分割 before 返回前面的值，after 返回后面的值
export function Specialcharacters(data, charecter, types) {
  if (data) {
    let dataArr = data.split(charecter);
    let strBefore = dataArr[0];
    let strAfter = "";
    dataArr.forEach((item, key) => {
      if (key !== 0) {
        if (key === dataArr.length - 1) {
          strAfter = strAfter + item;
        } else {
          strAfter = strAfter + item + "|";
        }
      }
    });

    if (types === "before") return strBefore;
    if (types === "after") return strAfter;
  } else {
    return data;
  }
}
export function iphoneCenter(iphone) {
  let iphones = "" + iphone;
  if (!iphone) return "******";
  return iphones.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
}

export function numBFBT(num1, num2) {
  let nums = (num1 || 0) / num2;
  return nums.toFixed(2) * 100;
}

// 名称排序

export function NameSort(Arrays) {
  Arrays.sort((a, b) => {
    let reg = /^[A-z]/;
    if (reg.test(a.name) || reg.test(b.name)) {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return a.name.localeCompare(b.name, "zh");
    }
  });
  return Arrays;
}

// 取字符前后的字段

export function extractFilenamePrefix(filename, sign, types) {
  if (!filename) return;
  let dotIndex = filename.indexOf(sign);
  let textLength = filename.length;

  if (types === "before") return filename.substring(0, dotIndex); //前
  if (types === "after") return filename.substring(dotIndex, textLength); // 后
}
// 批量上传时进行post请求  src/apps/exam/exam_tool/file2post
export function fileFormat(data) {
  // 二进制的部分不为空时 转换数据格式
  const dataObj = new FormData();
  dataObj.append("stateSplitTwo", "true");

  dataObj.append("otherData", "{}");

  dataObj.append(`files__file_obj`, data);

  return dataObj;
}

export function checkFileType(url, fileUrlType) {
  if (!url) return url;
  const imageExtensions = ["jpg", "jpeg", "gif", "bmp", "png"];
  const audioExtensions = ["mp3", "wav", "ogg", "aac"];
  const fileExt = url.split(".").pop().toLowerCase();

  if (fileUrlType === "image") return imageExtensions.includes(fileExt);
  if (fileUrlType === "audio") return audioExtensions.includes(fileExt);
}

// 取url后的文件名

export function getUrlFileName(url) {
  if (!url) return null;
  let lastOf = url.lastIndexOf('/') // '/'所在的最后位置
  let str = url.substr(lastOf + 1) //截取文件名称和后缀   输出：abc.jpg
  let strUrl = url.substr(0, lastOf) //截取路径字符串    输出：http://localhost:3000/static
  let pName = str.substring(0, str.lastIndexOf(".")) //截取文件名   输出：abc
  let suffix = url.substring(url.lastIndexOf(".")) //截取后缀   输出：.jpg
  let suffixType = url.substring(url.lastIndexOf(".") + 1) //截取后缀名  输出：jpg
  return {
    str: str,
    strUrl: strUrl,
    pName: pName,
    suffix: suffix,
    suffixType: suffixType,
  }
}
