import {
  AddNewTagRequesting,
  AddNewTagSuccess,
  AddNewTagError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function AddNewTag(state = defalutState, action) {
  switch (action.type) {
    case AddNewTagRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case AddNewTagSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case AddNewTagError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    default:
      return state;
  }
}
