import {
  GetBatchExamErrorListRequesting,
  GetBatchExamErrorListSuccess,
  GetBatchExamErrorListError,
} from "./../action-type";

const defalutState = {
  loading: false,
  data: null,
};

export default function GetBatchExamErrorList(state = defalutState, action) {
  switch (action.type) {
    case GetBatchExamErrorListRequesting:
      return Object.assign({}, state, {
        loading: true,
      });
    case GetBatchExamErrorListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value,
      });
    case GetBatchExamErrorListError:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
