import { Enlang, Zhlang } from "./../action-type";

const defalutState = {
  data: require("./../../component/language/zh"),
};

export default function Language(state = defalutState, action) {
  switch (action.type) {
    case Enlang:
      return Object.assign({}, state, {
        data: require("./../../component/language/en"),
      });
    case Zhlang:
      return Object.assign({}, state, {
        loading: false,
        data: require("./../../component/language/zh"),
      });
    default:
      return state;
  }
}
