import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./index.less";
import isLogin from "../component/isLogin";
import Loading from "../component/pageLoading";
const HomePage = lazy(() => import("./../views/HomePage"));
const TestPaperPage = lazy(() => import("./../views/EditUser/TestPaperPage"));
const ChapterPage = lazy(() => import("./../views/EditUser/ChaperPage"));
const LoginPage = lazy(() => import("./../views/login/login"));
const UserCenter = lazy(() => import("./../views/userCenter"));
const QuestionsPage = lazy(() => import("./../views/EditUser/QuestionsPage"));
const NoFile = lazy(() => import("./../component/noFile/noFile"));
const StudentListPage = lazy(() => import("./../views/Teacher/StudentList"));
// const MockListPage = lazy(() => import("./../views/Teacher/MockList"));
const AskQuestion = lazy(() => import("./../views/Teacher/AskQuestion"));
const FindQuestion = lazy(() => import("./../views/Teacher/FindQuestion"));
const TaPage = lazy(() => import("./../views/aiTa/ta/index"));
// const AnswerSheetListPage = lazy(() =>
//   import("../views/Teacher/AnswerSheetList")
// );
const TestTemplatePage = lazy(() =>
  import("./../views/EditUser/TestTemplatePage")
);
const BulkUploadPage = lazy(() => import("./../views/EditUser/BulkUpload"));
const RankingPage = lazy(() => import("./../views/Teacher/Ranking"));
class Routers extends React.Component {
  render() {
    return (
      <div className="routers">
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={isLogin(HomePage)} />
              {/* <Route exact path="/Teacher" component={isLogin(HomePage)} /> */}
              <Route path="/TestPaper" component={isLogin(TestPaperPage)} />
              <Route
                path="/TestPapers/:id/:code"
                component={isLogin(ChapterPage)}
              />
              <Route path="/BulkUpload" component={isLogin(BulkUploadPage)} />
              <Route path={"/login"} component={LoginPage} />
              <Route path="/userCenter" component={isLogin(UserCenter)} />
              <Route
                path={"/TestQuestions"}
                component={isLogin(QuestionsPage)}
              />
              <Route
                path="/TestTemPlate"
                component={isLogin(TestTemplatePage)}
              />
              <Route path="/StudentList" component={isLogin(StudentListPage)} />
              {/* <Route path="/MockList" component={isLogin(MockListPage)} /> */}
              {/* <Route
                path="/MockLists/:id/:user"
                component={isLogin(AnswerSheetListPage)}
              /> */}
              {/* <Route path={"/AskQuestion"} component={isLogin(AskQuestion)} /> */}
              <Route path={"/FindQuestion"} component={isLogin(FindQuestion)} />
              {/* <Route
                path={"/AnswerSheetList"}
                component={isLogin(AnswerSheetListPage)}
              /> */}
              <Route path={"/Ranking"} component={isLogin(RankingPage)} />
              <Route path={"/TA"} component={isLogin(TaPage)} />
              <Route path="*" component={isLogin(NoFile)} />
            </Switch>
          </Suspense>
        </BrowserRouter>
        {/* {getUserInfo() === null ? (
          <LoginPage />
        ) : isUser(getUserInfo().codes, "UPLOADER") ? (
          <Router>
            <EditHeader />
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/home">
                <HomePage />
              </Route>
              <Route path="/TestPaper">
                <Switch>
                  <Route path="/TestPaper/:id">
                    <ChapterPage />
                  </Route>
                  <Route path="/TestPaper">
                    <TestPaperPage />
                  </Route>
                </Switch>
              </Route>

              <Route path="/TestTemPlate">
                <TestTemplatePage />
              </Route>
              <Route path="/one">
                <Topics />
              </Route>
              <Route path={"*"}>
                <div>404</div>
              </Route>
            </Switch>
          </Router>
        ) : (
          <Router>
            <StudentHeader />
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/home">
                <HomePage />
              </Route>
              <Route path="/mock">
                <TestPaperPage />
              </Route>

              <Route path="/one">
                <Topics />
              </Route>
              <Route path={"*"}>
                <div>404</div>
              </Route>
            </Switch>
          </Router>
        )} */}
      </div>
    );
  }
}

export default Routers;
