import { combineReducers } from "redux";
import login from "./login";
import GetMockList from "./getMockList";
import GetTemplateList from "./GetTemplateList";
import CreateTest from "./createTest";
import GetChaperList from "./getChaperList";
import GetQustionList from "./getQuestionList";
import GetQustionDetails from "./getQuestionDetails";
import AddFile from "./addFile";
import PostQuestionImg from "./postQuestionImg";
import PostQuestionText from "./postQuestionText";
import BindQuestionTag from "./bindQuestionTag";
import DELETEQuestionTag from "./deleteQuestionTag";
import AddNewTag from "./addNewTag";
import GetUserInfo from "./getUserInfo";
import PostPassWord from "./postPassword";
import PostUserInfo from "./postUserInfo";
import DELETEQuestionImg from "./deleteQuestionImg";
import GetTestTypesList from "./getTestTypesList";
import GetTagTypes from "./getTagTypes";
import GetStudentList from "./getStudentList";
import GetTeacherMockList from "./getTeacherMockList";
import GetAnswerSheetList from "./getAnswerSheetList";
import GetAnswerSheetDetails from "./getAnswerSheetDetails";
import GetTargetList from "./getTargetList";
import GetTaStudentList from "./getTaStudentList";
import GetWankingList from "./getWankingList";
import PostBatchExam from "./postBatchExam";
import GetBatchExamList from "./getBatchExam";
import GetBatchExamErrorList from "./getBatchExamError";
import Language from "./lang";
export default combineReducers({
  login,
  GetMockList,
  GetTemplateList,
  CreateTest,
  GetChaperList,
  GetQustionList,
  GetQustionDetails,
  AddFile,
  PostQuestionImg,
  PostQuestionText,
  BindQuestionTag,
  DELETEQuestionTag,
  AddNewTag,
  GetUserInfo,
  PostPassWord,
  PostUserInfo,
  DELETEQuestionImg,
  GetTestTypesList,
  GetTagTypes,
  GetStudentList,
  GetTeacherMockList,
  GetAnswerSheetList,
  GetAnswerSheetDetails,
  GetTargetList,
  GetTaStudentList,
  GetWankingList,
  PostBatchExam,
  GetBatchExamList,
  GetBatchExamErrorList,
  Language,
});
