// 登录
export const loginRequesting = "loginRequesting";
export const loginSuccess = "loginSuccess";
export const loginError = "loginError";
// 语言切换

export const Enlang = "Enlang";
export const Zhlang = "Zhlang";

// PostPassWord

export const PostPassWordRequesting = "PostPassWordRequesting";
export const PostPassWordSuccess = "PostPassWordSuccess";
export const PostPassWordError = "PostPassWordError";

// PostUserInfo

export const PostUserInfoRequesting = "PostUserInfoRequesting";
export const PostUserInfoSuccess = "PostUserInfoSuccess";
export const PostUserInfoError = "PostUserInfoError";

// GetUserInfo

export const GetUserInfoRequesting = "GetUserInfoRequesting";
export const GetUserInfoSuccess = "GetUserInfoSuccess";
export const GetUserInfoError = "GetUserInfoError";

// add File

export const AddFileRequesting = "AddFileRequesting";
export const AddFileSuccess = "AddFileSuccess";
export const AddFileError = "AddFileError";

// GetTemplateList

export const GetTemplateListRequesting = "GetTemplateListRequesting";
export const GetTemplateListSuccess = "GetTemplateListSuccess";
export const GetTemplateListError = "GetTemplateListError";

// GetMockList

export const GetMockListRequesting = "GetMockListRequesting";
export const GetMockListSuccess = "GetMockListSuccess";
export const GetMockListError = "GetMockListError";

// CreateTest

export const CreateTestRequesting = "CreateTestRequesting";
export const CreateTestSuccess = "CreateTestSuccess";
export const CreateTestError = "CreateTestError";

// GetChaperList

export const GetChaperListRequesting = "GetChaperListRequesting";
export const GetChaperListSuccess = "GetChaperListSuccess";
export const GetChaperListError = "GetChaperListError";

// GetQustionList

export const GetQustionListRequesting = "GetQustionListRequesting";
export const GetQustionListSuccess = "GetQustionListSuccess";
export const GetQustionListError = "GetQustionListError";

// GetQustionDetails

export const GetQustionDetailsRequesting = "GetQustionDetailsRequesting";
export const GetQustionDetailsSuccess = "GetQustionDetailsSuccess";
export const GetQustionDetailsError = "GetQustionDetailsError";

// PostQuestionText

export const PostQuestionTextRequesting = "PostQuestionTextRequesting";
export const PostQuestionTextSuccess = "PostQuestionTextSuccess";
export const PostQuestionTextError = "PostQuestionTextError";

// PostQuestionImg

export const PostQuestionImgRequesting = "PostQuestionImgRequesting";
export const PostQuestionImgSuccess = "PostQuestionImgSuccess";
export const PostQuestionImgError = "PostQuestionImgError";

// BindQuestionTag

export const BindQuestionTagRequesting = "BindQuestionTagRequesting";
export const BindQuestionTagSuccess = "BindQuestionTagSuccess";
export const BindQuestionTagError = "BindQuestionTagError";

// DELETEQuestionTag

export const DELETEQuestionTagRequesting = "DELETEQuestionTagRequesting";
export const DELETEQuestionTagSuccess = "DELETEQuestionTagSuccess";
export const DELETEQuestionTagError = "DELETEQuestionTagError";

// AddNewTag

export const AddNewTagRequesting = "AddNewTagRequesting";
export const AddNewTagSuccess = "AddNewTagSuccess";
export const AddNewTagError = "AddNewTagError";

// DELETEQuestionImg

export const DELETEQuestionImgRequesting = "DELETEQuestionImgRequesting";
export const DELETEQuestionImgSuccess = "DELETEQuestionImgSuccess";
export const DELETEQuestionImgError = "DELETEQuestionImgError";

// GetTestTypesList

export const GetTestTypesListRequesting = "GetTestTypesListRequesting";
export const GetTestTypesListSuccess = "GetTestTypesListSuccess";
export const GetTestTypesListError = "GetTestTypesListError";

// GetTagTypes

export const GetTagTypesRequesting = "GetTagTypesRequesting";
export const GetTagTypesSuccess = "GetTagTypesSuccess";
export const GetTagTypesError = "GetTagTypesError";

// GetTeacherMockList

export const GetTeacherMockListRequesting = "GetTeacherMockListRequesting";
export const GetTeacherMockListSuccess = "GetTeacherMockListSuccess";
export const GetTeacherMockListError = "GetTeacherMockListError";

// GetStudentList

export const GetStudentListRequesting = "GetStudentListRequesting";
export const GetStudentListSuccess = "GetStudentListSuccess";
export const GetStudentListError = "GetStudentListError";

// GetAnswerSheetList

export const GetAnswerSheetListRequesting = "GetAnswerSheetListRequesting";
export const GetAnswerSheetListSuccess = "GetAnswerSheetListSuccess";
export const GetAnswerSheetListError = "GetAnswerSheetListError";

// GetAnswerSheetDetails

export const GetAnswerSheetDetailsRequesting =
  "GetAnswerSheetDetailsRequesting";
export const GetAnswerSheetDetailsSuccess = "GetAnswerSheetDetailsSuccess";
export const GetAnswerSheetDetailsError = "GetAnswerSheetDetailsError";

// GetTargetList

export const GetTargetListRequesting = "GetTargetListRequesting";
export const GetTargetListSuccess = "GetTargetListSuccess";
export const GetTargetListError = "GetTargetListError";

// AITA

// GetTaStudentList

export const GetTaStudentListRequesting = "GetTaStudentListRequesting";
export const GetTaStudentListSuccess = "GetTaStudentListSuccess";
export const GetTaStudentListError = "GetTaStudentListError";

// GetWankingList

export const GetWankingListRequesting = "GetWankingListRequesting";
export const GetWankingListSuccess = "GetWankingListSuccess";
export const GetWankingListError = "GetWankingListError";

// PostBatchExam

export const PostBatchExamRequesting = "PostBatchExamRequesting";
export const PostBatchExamSuccess = "PostBatchExamSuccess";
export const PostBatchExamError = "PostBatchExamError";

// GetBatchExamList

export const GetBatchExamListRequesting = "GetBatchExamListRequesting";
export const GetBatchExamListSuccess = "GetBatchExamListSuccess";
export const GetBatchExamListError = "GetBatchExamListError";

// GetBatchExamErrorList

export const GetBatchExamErrorListRequesting =
  "GetBatchExamErrorListRequesting";
export const GetBatchExamErrorListSuccess = "GetBatchExamErrorListSuccess";
export const GetBatchExamErrorListError = "GetBatchExamErrorList";
